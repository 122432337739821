import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }
  
  getbyidasset(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `property/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidasset')));
  }

  getassetpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/get_property_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getassetpagination')));
  }

  changeassetStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/change_property_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeassetStatus')));
  }

  createasset(assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createasset')));
  }

  updateasset(id,assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `property/${id}`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createasset')));
  }

  allpropertytypelist(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `propertytype/realestate/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error allpropertytypelist')));
  }

  allitemtypelist(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `itemtype/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error allitemtypelist')));
  }

  allitemlistbyitemtypeid(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `item/item_list/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error allitemlistbyitemtypeid')));
  }

  createassetinventory(id, assetdata): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/add_propertyinventory/${id}`, assetdata)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createassetinventory')));
  }

  updateassetinventory(id, assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `property/update_propertyinventory/${id}`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createasset')));
  }

  getassetinventorypagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/property_inventory_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getassetinventorypagination')));
  }

  archieveproperty(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/archieve_property/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error archieveproperty')));
  }

  getallarchieveproperty(assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/archieved_properties`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallarchieveproperty')));
  }

  deleteasset(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(this.baseUrl + `property/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deleteasset')));
  }

  getinventorylistbyassetid(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/inventory_by_propertyid/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getinventorylistbyassetid')));
  }

  changeassetinventoryStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/change_inventory_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeassetinventoryStatus')));
  }

  getinventorylistbyinventoryid(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/property_inventory/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getinventorylistbyinventoryid')));
  }
  
  unarchieveproperty(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/unarchieve_property/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error unarchieveproperty')));
  }

  deleteassetinventory(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/delete_inventory/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deleteassetinventory')));
  }

  downloadcontractbyassetid(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `property/property_with_contracturl/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error downloadcontractbyassetid')));
  }


  getalldeleteproperty(assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `property/get_deleted_property_pagination`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallarchieveproperty')));
  }
}
