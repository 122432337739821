
import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class LessorService {
  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) { }

  createlessor(data): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor`, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }

  getcontractpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract/pagination_by_realestatemanager`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  getbyidlessor(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `lessor/`+id, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
  }

  getalllessor(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor/list`, { headers },{})
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
  }

  editlessor(data , id): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `lessor/`+id, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }

  getcontractpagination1(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor/get_lessor_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  deletecontract(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(this.baseUrl + `lessor/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletecontract')));
  }



  getdeletelisttpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor/lessor_deleted_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  changeadminStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor/change_lessor_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeadminStatus')));
  }

  getarchivedlisttpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `lessor/lessor_archieved_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  //For Accommodation
  
  getaccomodationcontarct(dataTablesParameters,id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `accommodation/get_accommodation_pagination/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  createaccomodation(data): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `accommodation`, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }

  deleteaccommodation(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(this.baseUrl + `accommodation/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletecontract')));
  }

  changeaccommodationStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `accommodation/change_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeadminStatus')));
  }

 

  getbyidaccommodation(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `accommodation/`+id, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
  }

  editaccommodation(data , id): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `accommodation/`+id, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }
}
