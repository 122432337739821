import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getinventorypagination(id,dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/inventory_list_by_contract_pagination/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getinventorypagination')));
  }

  getallcontractlist(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contract_update/contract_list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallcontractlist')));
  }

  getbyidinventory(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/view_contract_inventory/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidinventory')));
  }

  changeinventoryexitstatus(id, statusdata): Observable<any> {
 //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/change_on_exit_status/${id}`, statusdata)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeinventoryexitstatus')));
  }


}
