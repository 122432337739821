import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';
@Injectable({
  providedIn: 'root'
})
export class ManagerRoleService {
  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getroles(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + 'realestatemanagerrole', { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getroles')));
  }
}
