import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';


@Injectable({
  providedIn: 'root'
})
export class TenantService {
  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  gettenantpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/pagination_by_realestatemanager`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getassetpagination')));
  }

  archivetenanat(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/archieve/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error archivetenanat')));
  }

  changetenantStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/change_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changetenantStatus')));
  }

  getbyidtenant(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `tenant/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidtenant')));
  }

  getallarchievetenant(assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/archived_tenants_by_realestatemanager`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallarchievetenant')));
  }

  unarchievetenant(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/unarchieve/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error unarchievetenant')));
  }

  createtenantinvitaion(tenantdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/invitation`, tenantdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createtenantinvitaion')));
  }

  getallcountry(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `country/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallcountry')));
  }

  getallcities(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `city/list/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallcities')));
  }

  createtenant(tenantdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/add`, tenantdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createtenant')));
  }

  updatetenant(id, updatedata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/update/${id}`, updatedata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updatetenant')));
  }

  deletetenant(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(this.baseUrl + `tenant/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletetenant')));
  }

  uploadetenantdocuments(id, tenantdata): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/upload_file/${id}`, tenantdata)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error uploadetenantdocuments')));
  }

  gettenantdocumentspagination(id,dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/document_pagination/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error gettenantdocumentspagination')));
  }

  deletedocument(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/delete_document/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletedocument')));
  }

  updatetenantdocuments(id, tenantdata): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/update_document/${id}`, tenantdata)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updatetenantdocuments')));
  }

  getdocumentbyid(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/document/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getdocumentbyid')));
  }

  getallTenant(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/tenant_list`, { headers },{})
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
  }
  getalltenantswithid(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/tenant_list_real`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getalltenants')));
  }

  getalldeletetenant(assetdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `tenant/deleted_realestatemanager_pagination`, assetdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallarchievetenant')));
  }

}
